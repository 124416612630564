import React     from 'react';
import Img       from 'gatsby-image';
import PropTypes from 'prop-types';

import { findMedia } from '../../_helpers';

const Media = ({ mediaCollection, name, type, alt }) => {
  const media = findMedia(mediaCollection, name);
  if (type === 'fluid') {
    return <Img fluid={media.childImageSharp.fluid} alt={alt}/>;
  }
  if (type === 'fixed') {
    return <Img fixed={media.childImageSharp.fixed} alt={alt}/>;
  }
}

Media.propTypes = {
  mediaCollection: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['fluid', 'fixed'])
}

Media.defaultProps = {
  type: 'fluid'
}

export default Media;