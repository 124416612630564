import React                             from 'react';
import { graphql }                       from 'gatsby';
import { Flex, Box, useBreakpointValue } from '@chakra-ui/react';

import Media       from '@interness/web-core/src/components/media/Media/Media';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import CTASection  from '@interness/web-core/src/components/modules/CTASection/CTASection';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import HeaderImg   from '@interness/web-core/src/components/media/HeaderImg/HeaderImg';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';

const IhreTrauringePage = (props) => {
  const flexDirection = useBreakpointValue(
    {
      base: 'column-reverse',
      md: 'column-reverse',
      lg: 'row',
      xl: 'row',
      '2xl': 'row',
    },
    {
      fallback: 'md',
    },
  )
  return (
    <>
      <HeaderImage>
        <HeaderImg id={'146-ihre-trauringe-header'}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading>Ihre Trauringe</Heading>
        <b>Jetzt NEU!</b>
        <p>
          Wir haben eine Auswahl an Trauringen aus unserer Muster- Kollektion für Sie zusammengestellt und Sie können
          gemütlich zuhause auf dem Sofa Ihren Trau(m)ring aussuchen. Gerne können Sie die Ringe bei uns im Geschäft
          abholen, oder wir liefern Sie zu Ihnen nach Hause. Beraten können wir, wenn gewünscht, per Videochat.
        </p>

        <Spacer/>

        <Flex flexWrap="wrap">
          <Flex flexWrap="wrap">
            <Box width={['100%', '100%', '100%', '50%']} pr={[0, 0, 0, 6]}>
              <h3>Wiederaufbereitetes Feingold</h3>
              <p>
                Der Abbau von Gold und anderen Edelmetallen ist eine enorme Belastung für die Umwelt. Unsere
                Trauringhersteller verwenden aus diesem Grund ausschließlich recyceltes Edelmetall und orientieren sich
                an
                die strengen Auflagen der deutschen Umweltgesetze.
              </p>
            </Box>
            <Box width={['100%', '100%', '100%', '50%']}>
              <Media mediaCollection={props.data.images.images} name="146-gold" alt="Gold"/>
            </Box>
          </Flex>
          <Flex flexWrap="wrap" flexDirection={flexDirection}>
            <Box width={['100%', '100%', '100%', '50%']}>
              <Media mediaCollection={props.data.images.images} name="146-mig" alt="Made in Germany"/>
            </Box>
            <Box width={['100%', '100%', '100%', '50%']} pl={[0, 0, 0, 6]}>
              <h3>Made in Germany</h3>
              <p>
                Die Trauringe der Hersteller mit denen wir zusammenarbeiten zeichnet sich durch eine hohe Qualität,
                Beständigkeit und gutes Design aus. 100% made in Germany.
              </p>
            </Box>
          </Flex>
          <Flex flexWrap="wrap">
            <Box width={['100%', '100%', '100%', '50%']} pr={[0, 0, 0, 6]}>
              <h3>Diamanten aus konfliktfreien Gebieten</h3>
              <p>
                Ein Diamant sollte immer aus konfliktfreien Gebieten stammen. Unsere Trauringhersteller haben
                schriftlich
                versichert,
                dass sie ihre Diamanten nur bei Händlern beziehen, die die Vorgaben des Kimberley-Prozesses einhalten.
                Dieser
                unterbindet seit dem Jahre 2000 den Handel von sogenannten Blutdiamanten, durch deren Verkauf in
                Konfliktgebieten
                Kriege finanziert werden.
              </p>
            </Box>
            <Box width={['100%', '100%', '100%', '50%']}>
              <Media mediaCollection={props.data.images.images} name="136-diamonds" alt="Diamanten"/>
            </Box>
          </Flex>
        </Flex>
        <Spacer/>
      </Wrapper>
      <CTASection/>
    </>
  )
}

export default IhreTrauringePage;

export const query = graphql`
    query {
        images: directusMediaCollection(name: {eq: "ihre-trauringe"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(quality: 95, maxWidth: 640 maxHeight: 640) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;